<template>
 
    <div style="margin-top: 100px">
       <BasePageBreadCrumb :pages="pages" :title="` Lista de arquivos`"/>
   
       <div class="card-body">
        <div class="col-12 p-0 d-none d-md-block">
            <div class="card-group my-3">
                <div class="card border-right">
                    <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div class="d-inline-flex align-items-center">
                                    <h2 class="text-dark mb-1 font-weight-medium">{{enterprises_files.total}}</h2>
                                </div>
                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">arquivos</h6>
                            </div>
                            <div class="ml-auto mt-md-3 mt-lg-0">
                                <span class="opacity-7 text-muted"><i class="far fa-list-alt font-25"></i></span>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card border-right">
                    <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <h2 class="text-dark mb-1 w-100 text-truncate font-weight-medium">0</h2>
                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">downloads</h6>
                            </div>
                            <div class="ml-auto mt-md-3 mt-lg-0 pr-1">
                                <span class="opacity-7 text-muted"><i class="far fa-hourglass font-25"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-right">
                    <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div class="d-inline-flex align-items-center">
                                    <h2 class="text-dark mb-1 font-weight-medium">{{enterprises_files.total_brokers}}</h2>
                                    <span class="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-lg-block d-md-none mb-2">+18.33%</span>
                                </div>
                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">corretores</h6>
                            </div>
                            <div class="ml-auto mt-md-3 mt-lg-0">
                                <span class="opacity-7 text-muted"><i class="far fa-thumbs-up font-25"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <h2 class="text-dark mb-1 font-weight-medium">{{enterprises_files.total_enterprises}}</h2>
                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">imóveis</h6>
                            </div>
                            <div class="ml-auto mt-md-3 mt-lg-0">
                                <span class="opacity-7 text-muted"><i class="icon-globe font-29"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>

       <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <form @submit="fetchEnterpriseFiles">
                <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-9">
                            <div class="form-group  d-flex">
                                <input type="search" class="form-control campo-procurar" v-model="search" placeholder="Procurar">
                                <select v-model="enterprises_files_types_id" class="custom-select text-dark mr-sm-2 campo-filter-2" id="inlineFormCustomSelect" style="max-width: 180px">
                              
                                    <option value="" selected="">Tipo</option>
                                    <option value="1">Tabela comercial do mês</option>
                                    <option value="2">Kit marketing</option>
                                    <option value="3">Imagens e plantas</option>
                                    <option value="4">Documentos</option>
                                    <option value="5">Vídeos</option>
                                </select>
                                <button type="submit" class="btn btn-info">Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </form>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-6 col-lg-9 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="d-flex">
                                                    <div class="col-12 col-md-12 col-lg-5 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Empreendimentos/Corretores</p>
                                                    </div>
                                                    <!-- <div class="col-8 col-md-4 col-lg-3 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">N˚ corretores</p>
                                                    </div> -->
                                                    <div class="col-12 col-md-6 col-lg-3 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Arquivos enviados</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-3 text-center align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Arquivo</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="item in enterprises_files.data" :key="item.id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row position-relative"  v-if="item != null && item.type != null && item.enterprise != null">
                                        <div v-if="item.enterprise != null" class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <img :src="item.enterprise.image" class="rounded-circle card-body-list-img" >
                                        </div>

                                        <div class="col-7 col-md-7 col-lg-9 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="d-flex">
                                                    <div v-if="item.enterprise != null" class="col-12 col-md-12 col-lg-5 align-self-center pad-content-list">
                                                            <h6>{{item.type.name }}</h6>
                                                        <h6 class="text-truncate card-body-list-title d-none d-lg-block">{{item.enterprise.name}}</h6>
                                                    </div>
                                                    <!-- <div class="col-8 col-md-4 col-lg-3 align-self-center pad-content-list">
                                                        <h6 class="text-truncate apto">{{item.brokers.length}}</h6>
                                                    </div> -->
                                                    <div v-if="item.type != null" class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list">
                                                    
                                                        <h6 class="text-truncate empreendi">{{item.description !=null?item.description :item.type.name }}</h6>
                            
                                                    </div>

                                                    <div v-if="item.path_name != null && item.path_name !=''" class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <p v-if="item?.type?.name == 'Imagens e plantas'"><b style="background:purple" class="badge badge-primary text-white" >{{item.path_name.split(".").slice(-1).join("")}}</b></p>
                                                        <p v-if="item?.type?.name !== 'Imagens e plantas' && item?.type?.name !='Vídeos'"><b style="background:purple" class="badge badge-primary text-white" ><a  target="_blank" class="text-white" :href="item.path_name">{{item.path_name.split(".").slice(-1).join("")}}</a></b></p>
                                                        <p v-if="item?.type?.name !== 'Imagens e plantas' && item?.type?.name !='Vídeos'"><b style="background:purple" class="badge badge-primary text-white" ><a  target="_blank" class="text-white" :href="item.path_name">{{item.path_name.split(".").slice(-1).join("")}}</a></b></p>
                                                        <p v-if="item?.type?.name =='Vídeos'"><b style="background:purple" class="badge badge-primary text-white" ><a target="_blank" class="text-white" :href="item.path_name">Vídeo</a></b></p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <b-link class="btn btn-success rounded-circle btn-circle mr-2 ml-2 font-20 card-body-list-cta" :to="`/lista-arquivos/${item.id}`">+</b-link>
                                            <a href="#" @click="deleteFileEnterprise(item)" class="btn btn-danger mr-2 ml-2 text-white rounded-circle btn-circle font-16"><i class="far fa-trash-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--LOOP-->
                    

                </div>
            </div>
            <div class="col-12">
                    <BasePagination
                        v-if="enterprises_files.data !=null && enterprises_files.data.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
        </div>
    </div>

    </div>
       
   </template>
     <script>

import EnterpriseFileService from "@/services/resources/EnterpriseFileService";
  const serviceEnterpriseFile = EnterpriseFileService.build();
     
     export default {
       data() {
         return {
           search: '',
           enterprises_files_types_id: "",
           enterprises_files: {},
           pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
           },
           pages:[
               {
                   name: 'dashboard',
                   to: 'Dashboard'
               },
               {
                   name: 'imóveis',
                   to: 'Enterprise'
               }
           ],
    
         };
       },
       methods: {

        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchEnterpriseFiles();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchEnterpriseFiles();
        },

        fetchEnterpriseFiles(e = null) {
            if(e)
            e.preventDefault();

        let data = { page: this.pagination.currentPage, search: this.search, enterprises_files_types_id: this.enterprises_files_types_id}
        serviceEnterpriseFile.search(data)
        .then(resp => {

            console.log(resp);
   
            this.enterprises_files = resp;

            this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
        })
            .catch(err => {
          
            })

      },
       
       deleteFileEnterprise(item){
           let data = {
               id: item.id
           };
   
           serviceEnterpriseFile
           .destroy(data)
           .then(resp => {
               this.$bvToast.toast('Arquivo excluído com sucesso!', {
                   title: 'Excluir pedido',
                   autoHideDelay: 5000,
                   type: 'success'
               })
               this.fetchEnterpriseFiles();
   
           })
           .catch(err => {
               this.$bvToast.toast('Seu pedido não pode ser excluído.', {
                   title: 'Excluir pedido',
                   autoHideDelay: 5000,
                   type: 'danger'
               })
           })
   
       },
      
         toPage(page) {
             this.pagination.currentPage = page;
             this.fetchEnterpriseFiles();
         },
         perPage(qtd) {
             this.pagination.currentPage = 1;
             this.pagination.perPage = qtd;
             this.fetchEnterpriseFiles();
         },
        
       },
       mounted() {
            this.fetchEnterpriseFiles();
       },
       computed: {
           
         
       },
       watch: {
        enterprises_files_types_id() {
            this.fetchEnterpriseFiles();
        }
       }
     };
     </script>
     <style scoped lang="scss">
   
     </style>